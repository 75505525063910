import React from 'react';
import './WhatDoYouGet.scss'

const WhatDoYouGet: React.FC =  () => {



    return (
        <section >
        <div className='container pb-2 '>
        <div className='row bg-light py-5 rounded-2'>
            <h2>We provide you tools that are a perfect fit for your development processes</h2>
          </div>

        </div>
        <div className='container py-5 mb-5 bg-dark'>         
          <div className='row '>
  <div className='col-sm-4 mb-3 mb-sm-0'>
    <div className='card h-100 border border-0 rounded rounded-2 shadow'>
      <div className='card-body'>
        <h5 className='card-title'>General cli module [@]</h5>
        <p className='card-text'>Provides general cli support options for everyday developoment needs, such as adding new features, documentation, writing unit tests.</p>
        <a href='#' className='btn btn-secondary'>Explore general aiup cli tool</a>
      </div>
    </div>
  </div>
  <div className='col-sm-4'>
    <div className='card h-100 border border-0 rounded rounded-2 shadow'>
      <div className='card-body'>
        <h5 className='card-title'>BugSmasher module [!]</h5>
        <p className='card-text'>With this add-on, you can provide descriptions of defects in your software and our aiup cli tool will guide you through the process of resolving those issues using ai</p>
        <a href='#' className='btn btn-secondary'>Explore BugSmasher</a>
      </div>
    </div>
  </div>
  <div className='col-sm-4'>
    <div className='card h-100 border border-0 rounded rounded-2 shadow'>
      <div className='card-body'>
        <h5 className='card-title'>Autopilot module &lt;*&gt; </h5>
        <p className='card-text'>This add-on will supercharge your development by interactively assisting in developing new features for your applications, tackling defects, performing refactoring, and even performing self-healing through analysis of log files</p>
        <a href='#' className='btn btn-secondary'>Explore autopilot module</a>
      </div>
    </div>
  </div>
</div>
        </div>
      </section>
    );
}


export default WhatDoYouGet;