import React, { useEffect} from 'react';
import './MainBanner.scss'

const MainBanner: React.FC = ()=> {
    useEffect(() => {
        const handleParallax = () => {
            const banner = document.querySelector('.banner') as HTMLElement;
            const scrollPosition = window.scrollY;
            const parallaxValue = scrollPosition * 0.5;
            banner.style.backgroundPosition = `center ${parallaxValue}px`;
        };

        window.addEventListener('scroll', handleParallax);

        return () => {
            window.removeEventListener('scroll', handleParallax);
        }        
    }, []);

    return  (
        <section className="banner" style={{ backgroundImage: 'url("officetable.jpg")', backgroundSize: 'cover'}}>
        <div className="d-flex flex-row justify-content-center" >
          <div className="w-50 text-white bg-secondary rounded-3" style={{ "--bs-bg-opacity": '.75'} as React.CSSProperties}>
            
              <h1 className=''>We build you ai tools that understand your business</h1>
              <p>
              Our approach involves a thorough analysis of your processes, enabling us to develop bespoke AI tools that are not just advanced, but also perfectly aligned with your needs. We're here to empower your team with cutting-edge AI, transforming the way you work and accelerating your journey towards innovation and efficiency
              </p>                        
          </div>
        </div>
      </section>
    )
}

export default MainBanner;