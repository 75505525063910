import React from 'react';
import './SecondBanner.scss'

const SecondBanner: React.FC =  () => {



    return (
        <section >
        <div className='container shadow-lg py-5 mb-5'>
          <div className='d-flex align-items-center justify-content-center p-5'>
            <div className='fs-3 text-wrap w-25 pe-5'>
              It's like bringing more heads to the table when you start using our ai tools
            </div>      
            <div className='' >
                <img src='./lotsofworkers.jpg' alt='workers in office' style={{ width: '400px'}}></img>
                </div>      
          </div>
        </div>
      </section>
    );
}


export default SecondBanner;