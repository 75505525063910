import React from 'react';
import logo from './logo.svg';
import './App.scss';
import MainBanner from './MainBanner';
import SecondBanner from './SecondBanner';
import WhichIndustryBanner from './WhichIndustryBanner';
import WhatDoYouGet from './WhatDoYouGet';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src="aiupstart-logo-3.png" width={100} alt="Ai Upstart Logo" />
             
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link menu-btn" href="#about-us">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact-us">
                    Contact Us
                  </a>
                </li>
                {/* Add more menu items as needed */}
              </ul>
            </div>
          </div>
        </nav>
      </header>

      {/* Banner */}
      <MainBanner/>

      
      {/* <section className="banner" style={{ backgroundImage: 'url("officetable.jpg")', backgroundSize: 'cover'}}>
        <div className="d-flex flex-row justify-content-center" >
          <div className="w-50 text-white bg-secondary rounded-3" style={{ "--bs-bg-opacity": '.75'} as React.CSSProperties}>
            
              <h1 className=''>We build you ai tools that understand your business</h1>
              <p>
              Our approach involves a thorough analysis of your processes, enabling us to develop bespoke AI tools that are not just advanced, but also perfectly aligned with your needs. We're here to empower your team with cutting-edge AI, transforming the way you work and accelerating your journey towards innovation and efficiency
              </p>                        
          </div>
        </div>
      </section> */}

      {/* Main Content */}

      <SecondBanner/>

      <WhichIndustryBanner/>

      <WhatDoYouGet/>
      <section className="main-content">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>About Us</h2>
              <p>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                eget felis nisl. */}
              </p>
            </div>
            <div className="col">
              {/* <img src="about-us-image.jpg" alt="About Us" /> */}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <img src="contact-image.jpg" alt="Contact Us" />
            </div>
            <div className="col">
              <h2>Contact Us</h2>
              <p>
                Contact us today to learn more about our AI solutions and
                services.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="fixed-bottom p-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-4 d-flex flex-column justify-content-between">
              <p className="text-center">AI Upstart</p>
              <p>where everything we do is <em>AI</em></p>
              <span>&copy; {new Date().getFullYear()} AI Upstart Ltd</span>
            </div>
            <div className="col-2">
              <ul className="footer-menu">
                <li><a href="#about-us">About Us</a></li>
                <li><a href="#contact-us">Contact Us</a></li>
                {/* Add more menu items as needed */}
              </ul>
            </div>
            <div className="col-2">
              <ul className="footer-menu">
                <li><a href="#services">Services</a></li>
                <li><a href="#blog">Blog</a></li>
                {/* Add more menu items as needed */}
              </ul>
            </div>
            <div className="col-2">
              <ul className="footer-menu">
                <li><a href="#faq">FAQ</a></li>
                <li><a href="#privacy-policy">Privacy Policy</a></li>
                {/* Add more menu items as needed */}
              </ul>
            </div>
          </div>
        </div>

       
      </footer>
    </div>
  );
}

export default App;
