import React from 'react';
import './WhichIndustryBanner.scss'

const WhichIndustryBanner: React.FC =  () => {



    return (
        <section >
        <div className='container pb-5 mb-5'>
          <div className='row bg-light py-3 rounded-2'>
            <h3>How can you get started?</h3>
          </div>
          <div className='row  pt-3 shadow-lg '>
          <div className="card bg-light mx-5 border border-0" >
            <div className="card-body">
              <div className="d-flex justify-content-between py-5 ">

               <img src="./macterminal.jpg" className="card-img-top px-3" alt="..." style={{"width": "300px"}}/>
              <div>

              <h5 className="card-title">It starts with our cli</h5>
              <p className="card-text px-3">Our aiup cli is a developer's best friend. This is trained to understand your codebase and provide a cli interface bespoke to your software development needs</p>
              <button className="btn btn-secondary">Start with aiup cli ➜ </button>
              </div>


              </div>
            </div>
          </div>  
          </div>
        </div>
      </section>
    );
}


export default WhichIndustryBanner;